<template>
  <div class="list">
    <kdx-form-title content="商品列表"></kdx-form-title>
    <FormItem label="商品：" prop="goods" v-error-item.goods v-if="!detail">
      <Button type="primary" @click="showAddGoodsModal">+添加商品</Button>
      <Table ref="table" :columns="columns" :data="table" @on-selection-change="selectChange"></Table>
      <div class="footer-action" v-show="table.length > 0">
        <Checkbox v-model="isSelectAll" @on-change="checkboxChange"></Checkbox>
        <Button :disabled="selectRows.length === 0" @click="remove(selectRows)">删除</Button>
      </div>
    </FormItem>
    <Table v-else ref="table" :columns="cColumns" :data="table" @on-selection-change="selectChange"></Table>

    <template v-if="!detail">
      <kdx-modal-frame title="添加商品" v-model="showModal" :footerHide="true" @on-cancel="showModal = false" width="1200">
        <div class="modal-frame-content">
          <good-list modal @addGood="addGood"></good-list>
        </div>
      </kdx-modal-frame>

      <kdx-modal-frame v-model="value" title="修改商品信息" :width="450" @on-cancel="value = false" @on-ok="handleOk">
        <Form ref="form_info" :model="model" :rules="rules" :label-width="120" class="edit-information-modal">
          <FormItem label="商品数量：" prop="goods_num">
            <kdx-rr-input type="text" v-model="model.goods_num" number :fixed="0" :max-value="999999999"
              placeholder="商品数量" class="width-250">
              <span slot="append">件</span>
            </kdx-rr-input>
          </FormItem>
        </Form>
      </kdx-modal-frame>
    </template>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import GoodList from "@/views/goods/list/index";
export default {
  components: {
    GoodList,
  },
  props: {
    list: Array,
    default: [],
    detail: {
      type: Boolean,
      default: false
    },
    good: {
      type: Object,
      default: () => { }
    }
  },
  model: {
    prop: 'list',
    event: 'change'
  },
  data() {
    return {
      columns: [
        {
          type: 'selection',
          width: 40,
          align: 'center',
        },
        // {
        //   title: '排序',
        //   key: 'sort_by',
        //   width: 100,
        //   render: (h, params) => {
        //     return (
        //       <div class="inventory">
        //         <span>{params.row[params.column.key]}</span>
        //       </div>
        //     );
        //   }
        // },
        {
          title: '商品',
          key: 'shop',
          minWidth: 260,
          render: (h, params) => {
            let label = params.row.category || [];
            const labelComponent = label.map(item => h('span', {}, item.name));
            let getType;
            switch (params.row.type) {
              case '1':
                getType = {
                  goodsName: 'mark real',
                  goodsText: '实'
                };
                break;
              case '2':
                getType = {
                  goodsName: 'mark virtual',
                  goodsText: '虚'
                };
                break;
              default:
                getType = {};
                break;
            }
            return (
              <div class="content-box">
                {/* image */}
                <div class="image">
                  <img src={this.$media(params.row.thumb)} alt="" onError={e => { this.replaceImage(e); }} />
                </div>
                {/*商品标题 */}
                <div class="content">
                  <div class="content-text">
                    {/*1多规格0单规格*/}
                    {/* <span class="mark"
                      style={{ display: params.row.has_option === '1' ? 'inline-block' : 'none' }}>多</span> */}
                    <span class={getType.goodsName}>
                      {getType.goodsText}
                    </span>
                    <span class="text">{params.row.title}</span>
                  </div>
                  {/*商品标签 */}
                  {/* <p class="label">{labelComponent}</p> */}
                </div>
              </div>
            );
          }
        },
        {
          title: '价格',
          key: 'price',
          minWidth: 100,
          render: (h, params) => {
            const style = (this.goodAddEditModel.cost_price || this.detail) ? { 'text-decoration': 'line-through' } : {};
            return (
              <div class="price">
                <span
                  style={style}>￥{params.row[params.column.key]}</span>
                {(this.goodAddEditModel.cost_price || this.detail) ? <span
                  style={{ display: 'block', color: 'red' }}>￥{(this.totalDiscouPrice(params.row) / params.row.goods_num).toFixed(2)}</span> : ''}
              </div>
            );
          }
        },
        {
          title: '数量',
          key: 'goods_num',
          minWidth: 110,
          render: (h, params) => {
            let result = params.row[params.column.key];
            return (
              <div class="inventory">
                x<span> {result}</span>
                {this.detail ? '' : <span class="iconfont icon-bianji icon"
                  onClick={() => { this.updateInformation(params.row); }}>
                </span>}
              </div>
            );
          }
        },
        {
          title: '总价',
          key: 'goods_total_price',
          // sortable: 'custom',
          minWidth: 110,
          render: (h, params) => {
            const style = (this.goodAddEditModel.cost_price || this.detail) ? { 'text-decoration': 'line-through' } : {};
            return (
              <div class="price">
                <span
                  style={style}>￥{this.goodTotalPrice(params.row).toFixed(2)}</span>
                {(this.goodAddEditModel.cost_price || this.detail) ? <span
                  style={{ display: 'block', color: 'red' }}>￥{this.totalDiscouPrice(params.row).toFixed(2)}</span> : ''}
              </div>
            );
          }
        },
        {
          title: '创建时间',
          key: 'created_at',
          // sortable: 'custom',
          minWidth: 110,
          render: (h, params) => {
            return <p style='word-break:normal'>{params.row.created_at ? params.row.created_at : '-'}</p>;
          }
        },
        {
          title: '操作',
          key: 'action',
          minWidth: 80,
          render: (h, params) => {
            return (
              <div class="action">
                <Button type='text' onClick={() => {
                  this.remove([params.row]);
                }}>删除</Button>
              </div>
            );
          }
        }
      ],
      showModal: false,
      selectRows: [], // 选中项
      isSelectAll: false,
      information: {},

      model: {
        goods_num: '',
      },
      rules: {
        goods_num: [{
          required: true,
          message: '数量必填'
        }],
      },
      value: false,
    };
  },
  computed: {
    cColumns() {
      if (this.detail) {
        return this.columns.filter(e => e.key != 'action' && e.type != 'selection');
      }
      return this.columns;
    },
    totalDiscouPrice() {
      if (this.detail) {
        return row => this.detailTotalPrice(row);
      }
      return row => (this.goodAddEditModel.cost_price / this.goodAddEditModel.price * this.goodTotalPrice(row));
    },
    detailTotalPrice() {
      return row => (this.good.discou / this.good.money * this.goodTotalPrice(row));
    },
    goodAddEditModel() {
      return this.$store.state.goodAddEdit.model;
    },
    goodTotalPrice() {
      return item => (item.goods_num * item.discou);
    },
    table: {
      get() {
        return this.list;
      },
      set(val) {
        this.$emit("change", val);
      },
    },
  },
  created() {
    this.changePrice();
  },
  methods: {
    ...mapMutations("goodAddEdit", ["setModelProps"]),
    changePrice() {
      const totalPrice = this.goodAddEditModel.goods.reduce((a, b) => a + b.goods_num * b.discou, 0);
      this.setModelProps({ ...this.goodAddEditModel, price: totalPrice.toFixed(2) });
    },
    handleOk() {
      this.$refs['form_info'].validate(valid => {
        if (valid) {
          this.information.goods_num = this.model.goods_num;
          const item = this.table.find(e => e.id == this.information.id);
          item.goods_num = this.model.goods_num;
          this.value = false;
          this.changePrice();
        }
      });

    },
    // 修改数量
    updateInformation(row) {
      this.information = row;
      this.model.goods_num = row.goods_num;
      this.value = true;
      this.changePrice();
    },
    addGood(data) {
      console.log(data);
      const index = this.table.findIndex(e => e.id == data.id);
      if (index != -1) {
        return this.$Message.warning('已添加过该商品');
      }
      this.table.push(data);
      this.$Message.success('添加成功');
      this.changePrice();
      this.isSelectAll = this.selectRows.length === this.table.length;
    },
    // 选中项发生变化
    selectChange(selection) {
      this.selectRows = selection;
      this.isSelectAll = this.selectRows.length === this.table.length;
    },
    checkboxChange(status) {
      this.table.forEach((item, index) => {
        this.$set(this.table[index], '_checked', status);
      });
      if (status) {
        this.selectRows = this.table;
      } else {
        this.selectRows = [];
      }
    },
    remove(items) {
      this.$Modal.confirm({
        title: '提示',
        content: '确认删除?',
        onOk: async () => {
          items.forEach(e => {
            const index = this.table.findIndex(c => c.id == e.id);
            if (index != -1) {
              this.table.splice(index, 1);
            }
          });
          this.changePrice();
          this.isSelectAll = this.selectRows.length === this.table.length;
        },
        onCancel: () => { }
      });

    },
    showAddGoodsModal() {
      this.showModal = true;
    },
    handlers({ type, id, content, status, }) {
      this.$Modal.confirm({
        title: '提示',
        content,
        onOk: () => {

        },
        onCancel: () => { }
      });
    },
    formatData(data) {
      if (parseInt(data) < 9999) {
        return data;
      } else {
        return `9999+`;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.list {
  padding-bottom: 40px;

  /deep/ .ivu-table {
    td {
      .content-box {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;

        .image {
          width: 80px;
          height: 80px;
          margin-right: 10px;
          border: 1px solid $border-color;
          overflow: hidden;
          border-radius: 2px;
          box-sizing: border-box;
          flex-shrink: 0;

          &>img {
            width: 100%;
            height: 100%;
          }
        }

        .content {
          width: 0;
          flex: 1;

          .content-text {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            overflow: hidden;
            height: 60px;

            .mark {
              @include font-12-16;
              color: #ed4014;
              background-color: #ffefe6;
              border-radius: 2px;
              margin-right: 4px;
              width: 18px;
              height: 18px;
              display: inline-block;
              text-align: center;
              line-height: 18px;

              &:nth-last-child(2) {
                margin-right: 6px;
              }

              &.real {
                background-color: #F0FAFF;
                color: $brand-color;
              }

              &.virtual {
                background-color: #E6FFF9;
                color: #00C5C5;
              }

              &.secret {
                background-color: #FFCDD0;
                color: #FF000F;
              }
            }

            .text {
              @include font-14-20;
            }
          }

          .label {
            // margin-bottom: -10px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            >span {
              border-radius: 2px;
              background-color: $background-color;
              @include font-12-16;
              margin-right: 10px;
              color: $text-second;
              display: inline-block;
              height: 20px;
              line-height: 20px;
              padding: 0 8px;
            }
          }
        }
      }

      // 价格
      .price {
        color: $text-first;
        @include font-14-20-bold;

        .icon {
          display: none;
          cursor: pointer;
          font-size: 16px;
          vertical-align: middle;
          font-weight: normal;
        }

        &:hover {
          >.icon {
            display: inline-block;
          }
        }
      }


      .inventory {
        span {
          font-size: 16px;
        }

        .icon {
          /* display: none; */
          cursor: pointer;
          font-size: 18px;
          vertical-align: middle;
          color: #999;
          margin-left: 5px;
        }

        &:hover {
          >.icon {
            display: inline-block;
          }
        }
      }

      .market-tag {
        .status-text {
          padding-right: 8px;
          cursor: pointer;
        }
      }

      .action {
        margin-right: -8px;

        .ivu-btn-text {
          margin-right: 8px;
        }
      }
    }
  }

  .footer-page {
    border-top: 1px solid $border-color;
  }
}

.edit-information-modal {
  margin-top: 40px;
}
</style>