<template>
  <div class="detail">
    <div class="table-list" ref="goods_box" v-loading="loading">
      <div class="table-header">
        <Button type="primary" @click="handleEdit">编辑</Button>
      </div>
      <table-list ref="table" :data="table" detail></table-list>
      <shop-list style="margin-top: 40px;" v-if="!loading" v-model="goods" detail :good="table[0]"></shop-list>
    </div>
  </div>
</template>

<script>
import TableList from './components/TableList';
import ShopList from '../add/base/components/ShopList.vue';
import { model } from '@/store/goodAddEdit/jsTools/goodModelData.js';
export default {
  components: {
    TableList,
    ShopList
  },
  data() {
    return {
      table: [],
      loading: false,
      id: '',
      goods: []
    };
  },
  created() {
    this.id = this.$route.params.id;
    this.getData();
  },
  methods: {
    handleEdit() {
      const row = this.table[0];

      this.$router.push({
        path: '/main/goods/add',
        query: {
          id: row.id,
          // sb: row.sub_shop_id,
          operate: 'edit',
          status: this.status,
          stype: row.stype
        }
      });
    },
    async getData() {
      this.loading = true;
      const { info } = await this.$api.goods.goodsdetails({ sid: this.id }, { stype: 'multi' });
      this.table = [{
        ...model,
        ...info,
        thumb: info.pic,
        price: info.discou,
        cost_price: info.money,
        type: info.type + '',
        stock: info.inventory,
        real_sales: info.buyNum,
        created_at: this.$dateFormat('YY-mm-dd HH:MM:SS', new Date(info.date * 1000)),
        has_option: '0',
        status: this.status == 4 ? 4 : info.isRelease,
        stype: 'multi',
        content: info.content
      }];
      this.goods = JSON.parse(info.shopJson).map(e => {
        return {
          ...model,
          ...e
        };
      });
      this.loading = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.detail {
  border-radius: 2px;
  margin: 20px auto 0;
  background-color: #ffffff;
  padding: 20px 40px;

  .table-header {
    text-align: right;
  }
}
</style>